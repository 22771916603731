import DropDownMenu_ComboBoxInputNavigationComponent from '@wix/thunderbolt-elements/src/components/DropDownMenuComboBox/viewer/skinComps/ComboBoxInputNavigation/ComboBoxInputNavigation.skin';
import DropDownMenu_ComboBoxInputNavigationController from '@wix/thunderbolt-elements/src/components/DropDownMenuComboBox/viewer/DropDownMenuComboBox.controller';


const DropDownMenu_ComboBoxInputNavigation = {
  component: DropDownMenu_ComboBoxInputNavigationComponent,
  controller: DropDownMenu_ComboBoxInputNavigationController
};


export const components = {
  ['DropDownMenu_ComboBoxInputNavigation']: DropDownMenu_ComboBoxInputNavigation
};

